import italianMessages from 'ra-language-italian';

const customItalian = {
    general: {
        errors: {
            fetching_filter: 'Impossibile caricare il filtro %{name}',
        }
    },
    resources: {
        payments: {
            name: 'Ordine |||| Ordini',
            fields: {
                code: 'Codice',
                description: 'Descrizione',
                transaction_id: 'ID Transazione',
                longDescription: 'Descrizione lunga',
                payment_type: 'Tipologia',
                notes: 'Note',
                platform: 'Piattaforma',
                amount: 'Costo',
                status: 'Stato',
                created_by: 'Creato da',
                updated_at: 'Ultimo aggiornamento',
                actions: 'Azioni'
            },
            errors: {
              missing: 'Il campo è obbligatorio',
            },
            actions: {
                delete: 'Elimina',
                duplicate: 'Duplica',
                copy_link: 'Copia link',
                show: 'Visualizza',
                edit: 'Modifica'
            },
            notifications: {
                create: {
                    success: 'Richiesta di pagamento creata correttamente',
                    failure: 'Errore durante la creazione della richiesta di pagamento'
                },
                edit: {
                    success: 'Richiesta di pagamento aggiornata correttamente',
                    failure: 'Errore durante l\'aggiornamento  della richiesta di pagamento'
                },
                copy: {
                    success: 'Link alla transazione copiato correttamente',
                    success_short: 'Link transazione copiato!'
                },
                duplicate: {
                    success: 'Ordine duplicato correttamente',
                    failure: 'Errore durante la duplicazione dell\'ordine'
                },
                delete: {
                    success: 'Ordine eliminato correttamente',
                    failure: 'Errore durante l\'eliminazione dell\'ordine'
                }
            },
            filters: {
                status: 'Stato'
            },
        },
        status: {
            unexecuted: 'Non eseguito',
            completed: 'Completato',
            canceled: 'Annullato',
            failed: 'Fallito'
        },

    },
};

const overrides = {
    ra: {
        message: {
            delete_content: 'Sei sicuro di voler cancellare questo elemento?',
            bulk_delete_title: 'Cancella %{name} |||| Cancella %{smart_count} %{name}',
            delete_title: 'Cancella %{name} \'%{id}\'',
            edit_title: 'Modifica %{name} \'%{id}\'',
            show_title: 'Visualizza %{name} \'%{id}\'',
        }
    }
}

const italianWithOverrides = italianMessages;
Object.assign(italianWithOverrides.ra.message, overrides.ra.message);

const italian = {...italianWithOverrides, ...customItalian}

export default italian;
