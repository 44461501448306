import {useState, useEffect} from "react";
import {Fragment} from "react";
import {useListContext, useDataProvider, useNotify} from "react-admin";
import {Alert, Autocomplete, Skeleton, TextField} from "@mui/material";
import {useMutation} from "react-query";
import {useTranslate} from "ra-core";

export const CustomAutocompleteInput = props => {
  const {
    choices = [],
    choicesSource = '',
    listFilter,
    multiple = false,
    onChange,
    source,
    shouldTranslate,
    renderTextOption,
    resource,
    optionLabel = 'name',
    limitVisibleTags,
    ...rest
  } = props;
  const { filterValues, setFilters, displayedFilters } = useListContext();
  const limitTags = limitVisibleTags ?? 2;
  const [options, setOptions] = useState(choices ?? []);
  const [error, setError] = useState(false);
  const [value, setValue] = useState(listFilter ? (filterValues[listFilter]) : (props.value ?? []));
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const notify = useNotify();
  const {mutate} = useMutation(
      ['getChoices', choicesSource],
      () => dataProvider.getChoices(choicesSource),
  );
  const loading = !options.length && choicesSource.length > 0;
  const translateOptions = (values) => {
    let translated = values;
    if(shouldTranslate) {
      for (const key in translated)
        translated[key][optionLabel] = `resources.${resource}.${source}.${translated[key][optionLabel]}`;
    }
    return translated;
  }

  useEffect(() => {
    if(listFilter){
      if(filterValues[listFilter] === undefined)
        setValue(multiple ? [] : '')
    }
    // eslint-disable-next-line
  }, [filterValues])



  const onSelectionChange = (event, values) => {
    event.stopPropagation();
    setValue(values);

    if(listFilter) {
      const currentFilterValues = {...filterValues};
      if(!values || (Array.isArray(values) && values.length === 0)) {
        delete currentFilterValues[listFilter];
      } else {
        currentFilterValues[listFilter] = !Array.isArray(values) ? [values.id] : values.map((value) => value.id)
      }
      setFilters(currentFilterValues, displayedFilters);
    }

    if(onChange) {
      onChange(event, values);
    }
  }


  const defaultValue = () => {
    if(multiple){
      const defaultValues = options.filter((option) => value.some((choice) => option.id === choice));
      if(listFilter && !defaultValues.length) return undefined;
      return defaultValues;
    }else{
     const defaultValue = options.find((option) => option.id === props.value) ?? '';
     return defaultValue ? defaultValue : '';
    }
  }

  useEffect(() => {
    if (!loading || !choicesSource) {
      return undefined;
    }
    mutate(null, {
      onSuccess: (data) => {
        setOptions(translateOptions(data.data));
        setError(false);
        if(listFilter) {
          const currentFilterValues = filterValues[listFilter] ?? [];
          let currentValue = multiple ? [] : null;
          if(currentFilterValues.length) {
            currentValue = data.data.filter((option) => { return currentFilterValues.includes(option.id);});
            if (!multiple) {
              currentValue = currentValue[0];
            }
          }
          setValue(currentValue);
        }
      },
      onError: (error) => {
        setError(true);
        if(listFilter)
          notify(translate('resources.errors.fetching_filter', {name:rest.label}), {type: 'error'})
      }
    });
    // eslint-disable-next-line
  }, [loading]);

  const defaultProps = {
    multiple: multiple,
    options: options,
    limitTags: limitTags,
    defaultValue: defaultValue,
    onChange: onSelectionChange
  }



  return (
      <Fragment>
        {!loading && value && !listFilter && <Autocomplete
            getOptionLabel={(option) => option[optionLabel] ?? ''}
            isOptionEqualToValue={(option, value) => value.id === option.id}
            renderOption={(props, option) => {
              return (
                  <li {...props} key={option.id}>
                    {option[optionLabel]}
                  </li>
              );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={rest.label}
                    placeholder={rest.label}
                    InputLabelProps={{
                      shrink: true
                    }}
                />
            )}
            disableClearable
            fullWidth={rest.fullWidth}
            {...defaultProps}
        />}
        {!loading && value && listFilter && <Autocomplete
            getOptionLabel={(option) => option[optionLabel] ?? ''}
            isOptionEqualToValue={(option, value) => value.id ? (value.id === option.id) : (value === option.id)}
            renderOption={(props, option) => {
              return (
                  <li {...props} key={option.id}>
                    {option[optionLabel]}
                  </li>
              );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={rest.label}
                    placeholder={rest.label}
                    InputLabelProps={{
                      shrink: true
                    }}
                />
            )}
            fullWidth={rest.fullWidth}
            {...defaultProps}
            value={value}
        />}
        {loading && !error && <Skeleton height={50} />}
        {loading && error && <Alert severity="error" sx={{marginBottom: 1, marginTop:1}}>{translate(listFilter ? 'resources.errors.fetching_filter' : 'resources.errors.fetching_select', {name:rest.label})}</Alert>}
      </Fragment>

  );
}
