import {useRecordContext, useTranslate} from "ra-core";
import {MenuItem} from "@mui/material";
import * as React from "react";
import {dataProvider} from "../../../../providers/DataProvider";
import {useNotify, useRefresh} from "react-admin";

export const DuplicateTransactionButton = (props) => {
    const {resource} = props;
    const record = useRecordContext();
    const [loading, setLoading] = React.useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const translate = useTranslate();

    const getPlatformToken = (platform) => {
        const tokens = {
            "Scuole_dev": '8c5c3fcbf55be2c6ecc87c437331c430',
            "strings.platform_tokens.salto": 'a82353d88046db1681fa6c0e07059432',
            "Espositori_dev": '19c0f82c3accfe34b92850e38118c12f',
            "Espositori": "40f3737303f768474601962a067c7693",
            "Scuole": "87d1ecfdabbea97afc54ee076106842d",
            "Comitive": "a5db77967279a29c62efc589e5fbca74",
            "Rights Centre": "472fb4dbd2cac06dc409cedd75b5bd2f",
            "Grande Pubblico / Professionali": "6d391fb5e0b775a65e07f03e8d465806",
        }
        return tokens[platform];
    }

    const handleDuplicate = () => {
        let data = {amount: record.amount, description: record.description+"_copia", long_description: record.longDescription, notes: record.notes, platform: getPlatformToken(record.platform)};
        setLoading(true);
        return new Promise(resolve => {
            dataProvider.create(resource,{data:data}).then((result) => {
                setLoading(false);
                notify(`resources.${resource}.notifications.duplicate.success`, {type: 'success'})
                refresh();
            }).catch(error => {
                const renderableError = error.body.data.description !== null && typeof error.body.data.description === 'object';
                if(renderableError) {
                    const errors = error.body.data.description;
                    let errorString = '';
                    for(let key in errors){
                        errorString += errors[key][0] + "\n";
                    }
                    notify(errorString, {type:'error', multiLine: true})
                }else{
                    notify(error.body.data.message, {type:'error', multiLine: true})
                }
                setLoading(false);
            });
        })


    }

    return (
        <MenuItem onClick={handleDuplicate} disabled={props.disabled || loading} className={props?.className}>{translate('resources.payments.actions.duplicate')}</MenuItem>
    )
}
