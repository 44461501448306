import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    SearchInput,
    useRefresh,
    BulkDeleteButton, useListContext, useListController, useNotify, TopToolbar, ChipField
} from 'react-admin';

import {Box, Typography} from "@mui/material";
import ModalCreateButton from "../../../ui/Modals/CreateForm/CreateForm";
import {PaymentFields} from "../../Payment/PaymentList/FormFields/Fields";
import {ActionsField} from "../../../ui/fields/ActionsField";
import {useTranslate} from "ra-core";
import {AmountField} from "../../../ui/fields/AmountField";
import {DateField} from "../../../ui/fields/DateField";
import {CustomAutocompleteInput} from "../../../ui/fields/AutocompleteInput";
import {ListActions} from "../../../ui/ListActions";
import {Exporter} from '../Exporter';
import {StatusField} from "../../../ui/fields/StatusField";
import {CustomPagination} from "../../../../assets/themes/DataTable";
import {useEffect, useState} from "react";
import {CopyTransactionLinkButton} from "../../../ui/fields/CopyTransactionLinkButton";
import {DuplicateTransactionButton} from "../../../ui/fields/DuplicateTransactionButton";

export const PaymentList = (props) => {
    const refresh = useRefresh();
    const translate = useTranslate();
    const notify = useNotify();
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const {resource, record} = useListContext({});
    const {isFetching, isLoading, error} =  useListController({
        resource: resource,
    });

    const PaymentPanel = () => {
        const {resource, record} = useListContext();
        return (
            <div className="expand-container">

            </div>
        );
    }

    const Empty = () => {
        return (
            <Box textAlign="center" m={1} >
                <Typography variant="h4" paragraph>
                    {translate('ra.page.empty', {name: translate('resources.payments.name', {smart_count: 1})})}
                </Typography>
                <Typography variant="body1">
                    {translate('ra.page.invite')}
                </Typography>
                <ModalCreateButton resource={'payments'} formFields={PaymentFields} open={openCreateModal} setOpen={setOpenCreateModal}/>
            </Box>
        );
    };
    // const handleChange = () => { refresh(); };
    // const filters = [
    //     <SearchInput key={'search-input'} source={'search'}  alwaysOn />,
    //     <CustomAutocompleteInput
    //         choicesSource={`payments/statuses`}
    //         resource={'payments'}
    //         optionLabel={'name'}
    //         label={'Status'}
    //         source={'status'}
    //         shouldRenderSuggestions
    //         shouldTranslate
    //         multiple
    //         alwaysOn
    //     />,
    // ];

    const search = [
        <SearchInput key={'search-input'} source={'search'}  alwaysOn/>,
    ];

    const GroupActions = () => {
        const {resource} = useListContext();
        return (
            <TopToolbar className={"group-actions"}>
                <ModalCreateButton resource={resource} open={openCreateModal} setOpen={setOpenCreateModal}/>
            </TopToolbar>
        );
    }

    useEffect( () => {
        if(!isFetching && !isLoading && error)
            notify('ra.notification.http_error', {type: 'error'})
        // eslint-disable-next-line
    }, [isLoading])

    return (
         <List {...props} pagination={<CustomPagination/>} actions={<GroupActions/>} exporter={Exporter} filters={search} empty={<Empty/>} >
                <Datagrid isRowSelectable={ record => record.status !== 'completed' }>
                    <TextField source="code" />
                    <TextField source="description" />
                    <AmountField source='amount'/>
                    <StatusField resource={'payment'} source='status' sortable={false}/>
                    <TextField source="created_by" />
                    <TextField source="platform" />
                    <DateField source="updated_at" />
                    <ActionsField
                        recordKey={'payment'}
                        resource={"payments"}
                        additionalActions={[
                            <CopyTransactionLinkButton resource={"payments"}/>,
                            <DuplicateTransactionButton resource={"payments"}/>
                        ]}
                        {...props}
                    />
                </Datagrid>
            </List>
    );
}
